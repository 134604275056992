import HttpHandler from "@/handler/HttpHandler";
import HttpConstant from "@/const/HttpConstant";


export default class GptChat {
    constructor() {
        this.http = new HttpHandler();
    }

    postMessage(data, sustain) {
        return this.http.post(`/api/gptChar/postMessage/${sustain}`, data, HttpConstant.APPLICATION_JSON_REQ);
    }

    getGptMessageList(data) {
        return this.http.post(`/api/gptChar/getGptMessageList`, data, HttpConstant.FORM_DATA_REQ);
    }

    /**
     * 获取是否持续对话状态
     * @returns {Promise<AxiosResponse<any>>}
     */
    getSustainState() {
        return this.http.post(`/api/gptChar/getSustainState`, null, HttpConstant.APPLICATION_JSON_REQ);
    }

    /**
     *清空当前持续对话信息
     * @returns {Promise<AxiosResponse<any>>}
     */
    delGptMessageSustain() {
        return this.http.delete(`/api/gptChar/delGptMessageSustain`, null, HttpConstant.APPLICATION_JSON_REQ);
    }

    /**
     * 获取gpt个性风格
     * @returns {*}
     */
    getGptSettings() {
        return this.http.post(`/api/gptChar/getGptSettings`, null, HttpConstant.APPLICATION_JSON_REQ);
    }

    /**
     * 设置gpt风格
     * @param data
     * @returns {*}
     */
    setGptSettings(data) {
        return this.http.put(`/api/gptChar/setGptSettings`, data, HttpConstant.APPLICATION_JSON_REQ);
    }

    /**
     * 获取聊天用户列表
     * @returns {Promise<AxiosResponse<any>>}
     */
    cacheUsers() {
        return this.http.post(`/api/gptChar/cacheUsers`, null, HttpConstant.APPLICATION_JSON_REQ);
    }
}